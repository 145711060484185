<template>
  <div>
    <b-button @click="$store.dispatch('makeToast',{ mesaj : 'Denem'})">Show Toast</b-button>
    Dashhh
    <br />
    {{ $store.state.userMail }}
    <br />
    {{ $store.state.userSifre }}
    <br />
    şirket id
    {{ $store.state.calisilanSirketId }}

    <br />
    şirket sayı
    {{ $store.state.userSirketler.liste.length }}


     <br />
    il ilçe sayı
    {{ $store.state.sirketAyarlar.ililceListesi.length }}

    <b-badge variant="warning" class="badge alert-warning">Warning</b-badge>
Şirket ID

<br>
Şirket Seç Aç
    {{$store.state.sirketSecAcilsin}}

    <br>
    aa
     {{  $store.state.cariTipler.filter(o => o.value ===1)[0].text }}
<br>
stoklar
{{$store.state.sirketAyarlar.tumStoklar[0]}}
  <br>
  diğr stok
<br>
  {{ $store.state.sirketAyarlar.tumStoklar.filter((o)=> o.Id == 21)}}
        
   
                          
  </div>
</template>

<script>
 
 export default {
    components: {
     
   
  },
    data() {
      return {
        
        
         
      }
    },
    methods: {
     
      
    }
  }
</script>

<style></style>
